<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { fetchCompanyListApi } from "@/api/common";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";

export default {


    import : { fetchCompanyListApi },
    components: { },
    props:['type_ana','selectedCompt'],
    data() {
      return {
      tableData: [],
    
      analyticForm: {
        account     : '',
        name        : '',
        structure_id: '',
        description : '',
        type        : '',
      },
      strList : null,
      submitted : false,
      submitform: false,
      submit    : false,
      typesubmit: false,
      companys  : [],
      strType : [
          {text: 'Direction',value : 1},
          {text: 'Division', value : 2},
          {text: 'Services', value : 3},
          {text: 'Service',  value : 4},
          {text: 'Section',  value : 5},
      ],
      structures : [],
      entreprises :[],
      disabledAdd : false,
      disabledEdit : true,
      disabledDelete : true,
      disabled : true
    };
  },
  validations: {
    analyticForm:{
      account     : { required },
      name        : { required },
      structure_id: { required },
      description : { required },
    }
  },
  watch:{
    selectedCompt(){
      if(this.selectedCompt.length && undefined != this.selectedCompt[0]){
        this.analyticForm.uuid         = this.selectedCompt[0].uuid;
        this.analyticForm.account      = this.selectedCompt[0].account;
        this.analyticForm.name         = this.selectedCompt[0].name;
        this.analyticForm.description  = this.selectedCompt[0].comments;
        if(this.analyticForm.type == "internal"){
          var sil = (this.selectedCompt[0].struct_id).length;
          switch (sil) {
            case 3:
                this.strList = 1;
                break;
            case 6:
                this.strList = 2;
                break;
            case 9:
                this.strList = 3;
                break;
            case 12:
                this.strList = 4;
                break;
            case 15:
                this.strList = 5;
                break;
        
            default:
                break;
          }
          this.getStr();
        }
        this.analyticForm.structure_id = this.selectedCompt[0].struct_id;
        this.disabledDelete = false
        this.disabledEdit   = false

      }else{
        this.clearForm();

      }
    } 
  },
  mounted(){
    

  },
  created(){
      this.analyticForm.type         = this.type_ana;
      if(this.type_ana == "external")
      this.fetchCompanyList();
      if(this.type_ana == "intra_company")
      this.fetchIntraList();

  },
  methods:{
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.clearForm();
    },
    editBtn(){
      this.disabledAdd  = false;
      this.disabledEdit = true;
      this.disabled     = false;
    },
    cancelBtn(){
      this.disabled = true;
      this.disabledAdd = false;
      this.disabledEdit = true;
    },
    getStr(){
         var urlStr
        switch (this.strList) {
            case 1:
                urlStr = "/organisation/hierarchy/direction/getDirectionsList";
                break;
            case 2:
                urlStr = "/organisation/hierarchy/division/getDivisionsList";
                break;
            case 3:
                urlStr = "/organisation/hierarchy/departement/getDepartementsList";
                break;
            case 4:
                urlStr = "/organisation/hierarchy/service/getServicesList";
                break;
            case 5:
                urlStr = "/organisation/hierarchy/section/getSectionsList";
                break;
        }
          let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
            this.$http.get(urlStr)
            .then((res) => {
                this.structures = res.data;
                
                loader.hide();
            }).catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
            })
            .finally(() => {});
    },
    clearForm(){
      this.disabledDelete = true
      this.disabledEdit   = true
      this.strList        = "";
      this.analyticForm.uuid         = "";
      this.analyticForm.account      = "";
      this.analyticForm.name         = "";
      this.analyticForm.structure_id = "";
      this.analyticForm.description  = "";
           
    },
    fetchCompanyList(){
      fetchCompanyListApi()
      .then(res => {this.entreprises = res.data.list;
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    fetchIntraList(){
       this.$http.post('/referentiel/intra_entreprise/list')
      .then(res => (this.entreprises = res.data.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    formSubmit() {
      this.analyticForm.type         = this.type_ana;
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http
        .post("/analytic/store", this.analyticForm)

        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.$emit('refrechTable', true);
              this.clearForm();
              this.disabledAdd  = false;
              this.disabledEdit = true;
              this.disabled     = true;
              break;

            case 500:
                    this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      }

    },
    deleteAccount() {
      if( this.analyticForm.uuid == ""){
        this.$toast.error("Aucun élément sélectionné");
      }else{
        Swal.fire({
          title: "Êtes-vous sûr de supprimer le type :" +  this.analyticForm.account + "?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Supprimer!",
        }).then((result) => {
          if (result.value) {
            this.$http
              .post("/analytic/delete/" + this.analyticForm.uuid)
              .then((res) => {
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                      this.$toast.success(res.data.original.msg);
                      this.$emit('refrechTable', true);
                      this.clearForm();
                    break;
  
                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
      }

    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          <ul id="StickyGeo">
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addGeo rounded-pillGeo float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit" class="btn btn-editGeo rounded-pillGeo float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledDelete" @click="deleteAccount" class="btn btn-deleteGeo rounded-pillGeo float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <fieldset :disabled="disabled">
          <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
            <h4 class="card-title mb-4 pr-5 col-12">
              Details 
            </h4>
            <div class="col-12 row">
              <div class="col-xs-12 col-md-3">
                <div class="form-group">
                  <label for="formrow-inputCity">Compte *</label>
  
                  <input type="text"  class="form-control" v-model="analyticForm.account" 
                    :class="{
                    'is-invalid': submitted && $v.analyticForm.account.$error,
                  }" placeholder=""
                  />
                </div>
              </div>
              <div class="col-xs-12 col-md-3">
                <div class="form-group">
                  <label for="formrow-inputCity">Désignation *</label>
  
                  <input type="text"  class="form-control" v-model="analyticForm.name" 
                   
                  :class="{
                    'is-invalid': submitted && $v.analyticForm.name.$error,
                  }" placeholder=""
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-3 "  v-show='type_ana == "internal"'>
                  <label for="formrow-inputCity">Type de Structure *</label>
  
                  <select class="form-control " @change="getStr()" v-model="strList" :class="{'disabledMS': disabled,}" >
                      <option v-for="strT in strType" :key="strT.value" :value="strT.value"> {{strT.text}} </option>
                  </select>
                  <div
                      v-if="submitted && $v.analyticForm.structure_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.analyticForm.structure_id.required"
                      >La structure est obligatoire.</span
                      >
                  </div>
              </div>
              <div class="col-sm-12 col-md-3" v-show='type_ana == "internal"'>
                  <label for="formrow-inputCity">Structure *</label>
  
                  <select class="form-control " v-model="analyticForm.structure_id" 
                  @blur="$v.analyticForm.structure_id.$touch"
                  :class="{
                  'is-invalid': submitted && $v.analyticForm.structure_id.$error,
                  'disabledMS': disabled,
                  }">
                      <option v-for="structure in structures" :key="structure.code" :value="structure.code"> {{structure.name}} </option>
                  </select>
                  <div
                      v-if="submitted && $v.analyticForm.structure_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.analyticForm.structure_id.required"
                      >La structure est obligatoire.</span
                      >
                  </div>
              </div>
              <div class="col-sm-12 col-md-6" v-show='type_ana == "intra_company"'>
                  <label for="formrow-inputCity">Structure *</label>
                  <select class="form-control " v-model="analyticForm.structure_id" 
                  @blur="$v.analyticForm.structure_id.$touch"
                  :class="{
                  'is-invalid': submitted && $v.analyticForm.structure_id.$error,
                  'disabledMS': disabled,
                  }">
                      <option v-for="entreprise in entreprises" :key="entreprise.code" :value="entreprise.code"> {{entreprise.intra_entreprises_name}} </option>
                  </select>
                  <div
                      v-if="submitted && $v.analyticForm.structure_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.analyticForm.structure_id.required"
                      >La structure est obligatoire.</span
                      >
                  </div>
              </div>
              <div class="col-sm-12 col-md-6" v-show='type_ana == "external"'>
                  <label for="formrow-inputCity">Entreprises *</label>
                  <select class="form-control " v-model="analyticForm.structure_id" 
                  @blur="$v.analyticForm.structure_id.$touch"
                  :class="{
                  'is-invalid': submitted && $v.analyticForm.structure_id.$error,
                  'disabledMS': disabled,
                  }">
                      <option v-for="entreprise in entreprises" :key="entreprise.code" :value="entreprise.code"> {{entreprise.company_name}} </option>
                  </select>
                  <div
                      v-if="submitted && $v.analyticForm.structure_id.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.analyticForm.structure_id.required"
                      >La structure est obligatoire.</span
                      >
                  </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="formrow-inputCity"
                    >Description</label
                  >
                  <textarea  class="form-control " v-model="analyticForm.description" 
                  :class="{
                    'is-invalid': submitted && $v.analyticForm.description.$error,
                    'disabledMS': disabled,
                  }" placeholder="" rows="1"
                  />
                </div>
              </div>
            </div>          
            <div class="row">
              <div class="col-md-12 float-right" v-show="!disabled"> 
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times text-white label-icon" ></i> Annuler
                </button>
                
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
                
              </div>
            </div>
          </form>
          </fieldset>
        </div>
            <!-- end card-body -->
      </div>
    </div>
    <!-- </Layout> -->
</template>
<style>
  #StickyGeo {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteGeo #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
.form-check-input{
  font-size: 24px;
}
</style>
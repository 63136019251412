<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Analyticform from "./components/Analyticform";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
// import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js' 
import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {

    page: {
        title: "Compte analytic",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader,Analyticform , Dataset, DatasetItem,/* DatasetInfo, DatasetPager, DatasetSearch, */ DatasetShow},
    data() {
    return {
      searchDisable : false,
      search: '',
      defaultShow: 5,
      selectedCompt: undefined,
      tableData: [],
      title: "Compte analytic ",
      List_acount: [],
      rows:'',
      active:false,
      selectedRows:[],
      Visitor:undefined,
      refrechTable:undefined,
      analyticsTypeTitle :[ ],
      items: [],
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      type_ana : "",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      cols: [
        {
          name: "Compte",
          field: "account",
          sort: ''
        },
        {
          name: "Désignation",
          field: "name",
          sort: ''
        },
        {
          name: "Structure",
          field: "structure",
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },
  created(){
    this.type_ana = this.$route.params.typeAnalytic;
    this.totalRows = this.tableData.length;
    this.analyticsTypeTitle["internal"] = "SH DP"; 
    this.analyticsTypeTitle["intra_company"] = "SH Extra DP";
    this.analyticsTypeTitle["external"] = "Extra SH";
    this.items = [  
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiel",
          to: {name:"master_data.referentiels"},
        },
        {
          text: "Compte analytic",
          href: "/master_data/referentiels/analytics",
        },
        {
          text: this.analyticsTypeTitle[this.$route.params.typeAnalytic],
          active: true,
        }];

    // fetch divisions from api

    // this.fetchVisitorList();
    this.getListPaginate();
  },
  mounted() {
    
  },
  watch:{
     refrechTable:{
      handler(val){
        this.fetchVisitorList();
        this.refrechTable=false;
      }
    },
      selectedRows(){
        if(this.selectedRows.length){
          //console.log(this.selectedRows[0]);
         this.selectedCompt = this.selectedRows[0];
        }
        else{
          this.selectedCompt =[];
        }
      },
      Visitor:{
          handler(val){       
            this.List_acount.push({
                Visitor_fullname:val.Visitor_fullname,
                Visitor_identity_card_number:val.Visitor_identity_card_number,
                Visitor_function:val.Visitor_function,
                Visitor_company_id:val.Visitor_company_id,
            });
            
          }
          
          
      }
  },
  methods:{
    clearSearch (){
      this.search = "";
      this.getListPaginate();
    },
    searchUnit (){
      this.getListPaginate();
    },
    onShowChange(){
      this.getListPaginate();
    },
    getListPaginate(page = 1){
      let loader = this.$loading.show();
      var show = this.defaultShow;
      this.searchDisable = true;
      this.$http.get('/analytic/listPagenate?type='+this.type_ana+'&page=' + page+'&show=' + show +'&q='+this.search)
      .then(res => { 
        this.selectedRows = []; this.selectedIndex = -1;
        this.List_acount  = res.data;
        loader.hide();
        this.searchDisable = false;
      })
      .catch(error => {
        console.log(error)
        loader.hide();})
    },
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    fetchVisitorList(){
      this.$http.post('/analytic/list',{type : this.type_ana})
      .then(res => { 
        this.selectedRows = []; this.selectedIndex = -1;
        this.List_acount = res.data.list})
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getVisitor(){
      this.$http.get('/analytic/list')
      .then(response => {
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshVisitor(){
      this.getVisitor();
    },
     deleteAccount(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/analytic/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                    
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
              this.fetchVisitorList();
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
     editVisitor(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/visitors_company/getSingleVisitorData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var visitor = res.data.original.data.visitor;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentselectedCompt = visitor;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

     fetchVisitorDetails(uuid = null) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/visitors_company/getSingleVisitorData/" + uuid)
        .then((res) => {
          var status = res.data.original.status;
          var visitor = res.data.original.data.visitor;
          loader.hide();
          switch (status) {
            case 200:
                this.currentselectedCompt = visitor;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadVisitoryDataToFormComponent(selectedCompt){
      if(selectedCompt){
        this.fetchOpsDetails(selectedCompt.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentselectedCompt.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentselectedCompt.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentselectedCompt.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentselectedCompt.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentselectedCompt.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(visitor){

      this.currentselectedCompt = visitor;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshCompanyTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Des Compte Analytics</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <div class="form-group col-xs-12  col-lg row">
                  <div class="col-9">
                      <input type="text"  :disabled="searchDisable" placeholder="Search" v-model="search" class="form-control form-control">
                    </div>
                  <div class="col-3">
                    <b-button variant="primary  btn-label" @click="searchUnit"> <i class="fas fa-search label-icon" ></i> Rechercher </b-button>
                    <b-button variant="warning ml-4  btn-label" @click="clearSearch"> <i class="fas fa-times-circle label-icon" ></i> Réinitialiser </b-button>
                  </div>  
                </div>
                <!-- <table class="table table-centered table-nowrap"> -->
                   <dataset v-slot="{ ds }" :ds-data="List_acount.data" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.account }}</td>
                                <td>{{ row.name }}</td>
                                <td>{{ row.structure }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show class="mb-2" v-model="defaultShow" @changed="onShowChange" :ds-show-entries="defaultShow"/>
                    <pagination
                        :data="Object.assign({},List_acount)"
                        :limit="defaultShow"
                        class="float-right"
                        @pagination-change-page="getListPaginate"
                      />
                  </div>
                </dataset>
               <!--    <v-table
                    class="table table-hover"
                    :data="List_acount"
                    :currentPage.sync="currentPage"
                    :pageSize="5"
                    @totalPagesChanged="totalPages = $event"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Compte</th>
                      <th>Désignation </th>
                      <th>Structure </th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                   <v-tr v-for="row in displayData" :key="row.rowID"  :row="row">
                      <td>
                        {{ row.account }}
                      </td>
                      <td>{{ row.name }}</td>
                      <td>{{ row.structure }}</td>
                    </v-tr>
                  </tbody>
                </v-table>
                <smart-pagination
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                /> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <Analyticform :selectedCompt="selectedRows"  :type_ana="type_ana" @refrechTable="refrechTable=$event"/>
        </div>
      </div>
    </div>
    </Layout>
</template>